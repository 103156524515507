// 54025
// 58875
$(document).ready(function () {
    $("#vhpEmbeddingVideosModal").on('show.bs.modal', function (event) {
        var modal = $(this);
        modal.find("#vhpIframe").empty();
        var button = $(event.relatedTarget);
        var recipient = button.data('whatever');
        var iframeVideoUrl = recipient + "?autoplay=1";
        modal.find("#vhpIframe").append('<iframe id="vhpvideo" class="vhp-video embed-responsive-item" src=' + iframeVideoUrl + ' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
        event.stopImmediatePropagation();
    });

    $("#vhpEmbeddingVideosModal").on('hide.bs.modal', function () {
        $("#vhpvideo").remove();
    });
// 89347
    $(".modalVideoLayout").on('show.bs.modal', function (event) {
        var modal = $(this);
        modal.find(".videoLayoutIframe").empty();
        var button = $(event.relatedTarget);
        var recipient = button.data('whatever');
        var iframeVideoUrl = recipient + "?autoplay=1";
        modal.find(".videoLayoutIframe").append('<iframe class="embedvideoLayout embed-responsive-item" src=' + iframeVideoUrl + ' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
        event.stopImmediatePropagation();
    });

    $(".modalVideoLayout").on('hide.bs.modal', function () {
        $(".embedvideoLayout").remove();
    });
});
